import React,{ useState } from 'react'
import { Row, Col, Form, Typography, Table, Flex, Image, Space } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../PageComponents';
import { AddEditRentDrawer } from '../AddEditRentDrawer';
import { AlertModal } from '../../AlertModal';


const { Text } = Typography


const RentReportTable = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ editrent, setEditRent ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)

  

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Rent Month
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'rentmonth',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Rent Amount
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'rentamount',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Added By
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'addedBy',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
            width: 170,
        },
        {
            title: <Text >Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true); setEditRent(row?.rentamount)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.rentmonth}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        }
    ];

    const data = [
        {
            key: '1',
            rentmonth: <Text >July</Text>,            
            rentamount: <Text >20,000</Text>,
            addedBy: <Text >Admin</Text>,
            timeStamp: <Text >11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name='Rent'   
                    onClick={()=> setVisible(true)}
                />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
        </Row>
        <AddEditRentDrawer 
            visible={visible}
            editrent={editrent}
            onClose={()=>{setVisible(false);setEditRent(null)}}
        />
        <AlertModal 
            visible={deleteitem}
            onClose={()=>setDeleteItem(false)}
        />
    </div>
  )
}

export {RentReportTable}