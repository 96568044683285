import React, { useState } from 'react'
import { Card, Dropdown, Form, Flex, Button } from 'antd'
import ReactApexChart from 'react-apexcharts';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../PageComponents';

const BarChart = () => {

  const [order, setOrder] = useState(1);
  const [form] = Form.useForm()
  const items = [
      { label: <a href="#">Yearly</a>, key: '0' },
      { label: <a href="#">Monthly</a>, key: '1' },
  ];

  const onClick = ({ key }) => {
      key = parseInt(key) + 1;
      setOrder(key);
      // filter(key);
    };

  const chartData = {
    series: [
      {
        name: 'Previous year',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      },
      {
        name: 'This year',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        
      },
      fill: {
        opacity: 1
      },
      grid: {
        show: false
      },
      colors: ['#F4BB8A', '#B27777']
    }
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Flex justify='space-between' align='center'>
          <ModuleTopHeading name='Income Comparison' /> 
          <Form
              form={form}
              layout="vertical"
          >
              <Flex justify='end' gap={10}>
                <Dropdown
                  menu={{ items, onClick }}
                  trigger={['click']}
                  className='margin-top'
                  >
                  <Button className='btnsave pad-x border0 fs-14 fw-400' >
                    Monthly
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Flex>
          </Form>
        </Flex> 
        <div className='pt-3'>
          <ReactApexChart 
            options={chartData.options} 
            series={chartData.series} 
            type="bar" 
            height={350} 
          />
        </div>
      </Card>
    </div>
  )
}

export { BarChart }
