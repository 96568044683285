import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Flex } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../PageComponents';
import { AlertModal } from '../../AlertModal';
import { AddEditExpenseDrawer } from '../../ExpenseComponents/AddEditExpenseDrawer';


const { Text } = Typography


const ExpenseReportTable = () => {

    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ editexpense, setEditExpense ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ reportonly, setReportOnly ] = useState(true)
  

    const columns = [
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Expense Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'expenseName',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Amount (SAR)
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'amount',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Added By
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'addedBy',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
        },
        {
            title: <Text >Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true); setEditExpense(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.expenseName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            expenseName: <Text >Internet Bill</Text>,            
            amount: <Text >2000</Text>,
            addedBy: <Text >Admin</Text>,
            timeStamp: <Text >11:02PM 9/18/16</Text>,
        },
        {
            key: '2',
            expenseName: <Text >Electricity Bill</Text>,            
            amount: <Text >5000</Text>,
            addedBy: <Text >Admin</Text>,
            timeStamp: <Text >11:02PM 9/18/16</Text>,
        },
        {
            key: '3',
            expenseName: <Text >Gas Bill</Text>,            
            amount: <Text >3000</Text>,
            addedBy: <Text >Admin</Text>,
            timeStamp: <Text >11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name='Expense Details'
                    onClick={()=>{setVisible(true);setReportOnly(true)}}
                 />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
        <AddEditExpenseDrawer
            visible={visible}
            editexpense={editexpense}
            reportonly={reportonly}
            onClose={()=> {setVisible(false);setEditExpense(null)}}
        />
        <AlertModal 
            visible={deleteitem}
            onClose={()=>setDeleteItem(false)}
        />
    </div>
  )
}

export {ExpenseReportTable}