import React from 'react'
import { Button, Col, Divider, Image, Modal, Row, Table, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ActionButton } from '../../PageComponents'

const { Text } = Typography
 
const PropertyModal = ({visiblemodal, onClose}) => {

    const navigate = useNavigate()

    const columns = [
        {
            title: <Text >Property Name</Text>,
            dataIndex: 'propertyName',
            key:'propertyName',
        },
        {
            title: <Text >Action</Text>,
            key: 'action',
            align:'center',
            render: (_, row) => (
                <ActionButton
                    title='Report'
                    icon={<Image src='/assets/icons/report.png' width={16} preview={false} />}
                    onClick={() => navigate('/report')}
                    className='border0 bg-transparent'
                />
            ),
        },
    ];

    const data = [
        {
            key: '1',
            propertyName: <Text >Lumbini</Text>,
        },
        {
            key: '2',
            propertyName: <Text >Kathmandu</Text>,
        },
        {
            key: '3',
            propertyName: <Text >Lahore</Text>,
        },
      ];

  return (
    <div>
        <Modal title='Properties' 
            width={700} 
            open={visiblemodal} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={null}
        >
            <Divider className='my-3' />
            <Row>
                <Col span={24}>
                    <Table
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={false}
                        // pagination={{
                        //     hideOnSinglePage: true,
                        //     total: 12,
                        //     // pageSize: pagination?.pageSize,
                        //     // defaultPageSize: pagination?.pageSize,
                        //     // current: pagination?.pageNo,
                        //     // size: "default",
                        //     // pageSizeOptions: ['10', '20', '50', '100'],
                        //     // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        //     showTotal: (total) => <Button className='bg-secondary-color border0 text-white'>Total: {total}</Button>,
                        // }}
                    />
                </Col>
            </Row>
        </Modal>
    </div>
  )
}

export {PropertyModal}