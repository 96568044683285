import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';
import { useNavigate } from 'react-router-dom';


const { Text } = Typography


const ClientPropertyTable = ({setEditProperty , deleteitem,setDeleteItem,visibleModal,setVisibleModal, setVisible}) => {
    
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Property Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'propertyName',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Client Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'clientName',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Property Address
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'propertyAddress',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Property Type
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'propertyType',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Ownership
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'ownership',
        },
        {
            title: <Text >Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 200,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true);setEditProperty({name: row?.propertyName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Add Image'
                        shape="circle"
                        icon={<Image src='/assets/icons/imgup.png' width={16} preview={false} />}
                         onClick={() => { setVisibleModal(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Report'
                        shape="circle"
                        icon={<Image src='/assets/icons/report.png' width={16} preview={false} />}
                        onClick={() => { navigate('/report')}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.clientName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            propertyName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                <Text >Lumbini</Text>
            </Space>,
            clientName: <Text >Cooper</Text>,
            propertyAddress: <Text >3605 Parker Rd.</Text>,
            propertyType: <Text >Apartment</Text>,
            ownership: <Text >Own</Text>,
        },
        {
            key: '2',
            propertyName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                <Text >Kathmandu</Text>
            </Space>,
            clientName: <Text >Jane Cooper</Text>,
            propertyAddress: <Text >3890 Poplar Dr.</Text>,
            propertyType: <Text >Room</Text>,
            ownership: <Text >Rented</Text>,
        },
        {
            key: '3',
            propertyName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                <Text >Sisne-Jaljala</Text>
            </Space>,
            clientName: <Text >Jane Cooper</Text>,
            propertyAddress: <Text >8558 Green Rd.</Text>,
            propertyType: <Text >Roof Top</Text>,
            ownership: <Text >Rented</Text>,
        },
        {
            key: '4',
            propertyName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                <Text >Chisapani</Text>
            </Space>,
            clientName: <Text >Jane Cooper</Text>,
            propertyAddress: <Text >8558 Green Rd.</Text>,
            propertyType: <Text >House</Text>,
            ownership: <Text >Rented</Text>,
        },
        {
            key: '5',
            propertyName: <Space>
                <Avatar
                    size={30}
                    icon={<img src="/assets/images/av-1.png" />}
                />
                <Text >Bheri-Kankrebihar</Text>
            </Space>,
            clientName: <Text >Jane Cooper</Text>,
            propertyAddress: <Text >8558 Green Rd.</Text>,
            propertyType: <Text >Roof Top</Text>,
            ownership: <Text >Rented</Text>,
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {ClientPropertyTable}