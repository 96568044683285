import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Image, Upload, message} from "antd"
import { MyInput, MyRentDate, MySelect } from "../../Forms"
import { PropertyCity, PropertyClient, PropertyFor, PropertyOwnership, Propertytype } from "../../../shared/lookups/lookups"

const { Title } = Typography
const { Dragger } = Upload;

const AddEditPropertyDrawer = ({visible, onClose,editproperty}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [ typeshow, setTypeShow ] = useState(false)
    const [ owner, setOwner] = useState(false)

    const handleChange = (e) => {
        setTypeShow(e === 'Client' ? true  : false )
    }

    const handleOwnership = (e) => {
        setOwner( e === 'Rented' ? true : false)
    }
  
    useEffect(() => {
        if (visible && editproperty) {
            
        } else {
            form.resetFields();
        }
    }, [visible, editproperty])

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

    // const prop = {
    //     name: 'contract',
    //     multiple: true,
    //     action: domainUrl + '/createPatient',
    //     headers: {
    //         Authorization: userToken,
    //     },
    //     onChange(info) {
    //         const { fileList } = info;
    //         setFileList([...fileList]);
    //         if (fileList.length > 0) {
    //             const medicalReportFiles = fileList.map(file => file.originFileObj);
    //             form.setFieldsValue({ ...form.getFieldsValue(), medicalReport: medicalReportFiles });
    //         } else {
    //             form.setFieldsValue({ ...form.getFieldsValue(), medicalReport: [] });
    //         }
    //     },
    // }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };


  return (
    <Drawer
        title={editproperty?'Edit Property':'Add New Property'}
        onClose={closeDrawer}
        open={visible}
        width={800}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editproperty? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Property Adding For'
                        name="propertyAddingFor" 
                        required
                        message='Please choose property adding for'
                        size='large'
                        placeholder=''
                        value={form.getFieldValue('urlType') || ''}
                        options={PropertyFor}
                        onChange={handleChange}
                    />
                </Col>
               {typeshow?
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Client Name'
                            name="clientName" 
                            required
                            size='large'
                            message='Please choose client name'
                            placeholder=''
                            value={form.getFieldValue('urlType') || ''}
                            options={PropertyClient}
                        />
                    </Col>
                    :
                    <></>
               }
                {/* <Col span={24}>
                    <PropertyDetails form={form} />
                    <OwnerDetails/>
                    <RentDetails/>
                </Col> */}
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-400">PROPERTY DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Property Name'
                        name='propertyName'
                        placeholder='e.g John Doe'
                        required
                        size='large'
                        message='Please enter property name'
                        value={form.getFieldValue("propertyName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Property City'
                        name="propertyCity" 
                        size='large'
                        required
                        message='Please choose property city name'
                        placeholder=''
                        value={form.getFieldValue("propertyCity") || ''}
                        options={PropertyCity}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Property Address'
                        name='propertyAddress'
                        size='large'
                        placeholder='e.g John Doe'
                        required
                        message='Please enter property address'
                        value={form.getFieldValue("propertyAddress") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Property Neighbourhood'
                        name='propertyNeighbourhood'
                        size='large'
                        placeholder='e.g abc de'
                        required
                        message='Please enter property neighbourhood'
                        value={form.getFieldValue("propertyNeighbourhood") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Property Type'
                        name="propertyType" 
                        size='large'
                        required
                        message='Please choose property type'
                        placeholder=''
                        value={form.getFieldValue('urlType') || ''}
                        options={Propertytype}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Ownership'
                        name="ownership"
                        size='large'
                        required
                        message='Please choose ownership' 
                        placeholder=''
                        value={form.getFieldValue('ownership') || ''}
                        options={PropertyOwnership}
                        onChange={handleOwnership}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Number of Bedrooms'
                        name='numberOfBed'
                        size='large'
                        placeholder='e.g 3'
                        required
                        message='Please enter a number'
                        value={form.getFieldValue("numberOfBed") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Number of Bathrooms'
                        name='numberOfBath'
                        size='large'
                        placeholder='e.g 3000'
                        required
                        message='Please enter a valid number'
                        value={form.getFieldValue("numberOfBath") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Furnished'
                        name="furnished" 
                        size='large'
                        required
                        message='Please choose yes or no'
                        placeholder=''
                        value={form.getFieldValue("furnished") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Yes',
                            },
                            {
                                key: 2,
                                name: 'No',
                            }
                        ]}
                    />
                </Col>

                {/* owner detail inputs */}
                {
                    owner?
                    <>
                        <Col span={24}>
                            <Title level={5} className="mt-0 mb-3 fw-400">OWNER DETAILS</Title>
                        </Col>
                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                label='Owner Name'
                                name='ownerName'
                                placeholder='e.g John Doe'
                                size='large'
                                required
                                message='Please enter Owner name'
                                value={form.getFieldValue("ownerName") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                label='Owner Email Address'
                                name='emailAddress'
                                placeholder='e.g John@xyz.com'
                                size='large'
                                required
                                message='Please enter email address'
                                value={form.getFieldValue("emailAddress") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                label='Owner Contact Number'
                                name='ownerContact'
                                placeholder='e.g abc.jpg'
                                size='large'
                                required
                                message='Please Upload owner contact number'
                                value={form.getFieldValue("ownerContact") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                label='Additional Information'
                                name='additionalInfo'
                                placeholder='e.g abgf'
                                required
                                message='Please enter additional information about property'
                                textArea
                                value={form.getFieldValue("additionalInfo") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Form.Item name='contract' >
                                <div className='my-3 width-drag'>
                                    <Dragger {...props} className="dragcs">
                                        <p className="ant-upload-drag-icon">
                                            <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                        </p>
                                        <Typography.Text className="fs-12">Upload Contract With Owner</Typography.Text>
                                    </Dragger>
                                </div>
                                {/* {
                                    editproperty &&
                                    <Space direction='vertical' size={10}>
                                        {
                                            geteditdata?.medical_report?.map((data,index)=>
                                                <div className='viewDownload space-between-align' key={index}>
                                                    <Typography.Text className='gray-text fs-12'>
                                                        {
                                                            data?.name
                                                        }
                                                    </Typography.Text>
                                                    <ActionButton
                                                        title='Delete'
                                                        icon={<DeleteOutlined className='danger-color' />}
                                                        onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                        type='ghost'
                                                    />
                                                </div>
                                            )
                                        }
                                    </Space>
                                    
                                } */}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Title level={5} className="mt-0 mb-3 fw-400">RENT DETAILS</Title>
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                label='Rent Amount'
                                name='rentAmount'
                                placeholder='e.g 1000'
                                size='large'
                                required
                                message='Please enter rent amount'
                                value={form.getFieldValue("rentAmount") || ''}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyRentDate
                                datePicker
                                label="Rent Date"
                                name='rentDate'
                                size='large'
                                required
                                message="Please select rent date"
                                value={form.getFieldValue('rentDate') || ''}
                                className='bg-transparent'
                            />
                        </Col>
                        <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                textArea
                                label='Additional Information'
                                name='additionalInfor'
                                placeholder='e.g abgf'
                                required
                                message='Please enter additional information'
                                value={form.getFieldValue("additionalInfor") || ''}
                            />
                        </Col>
                    </>
                    :
                    <></>
                }
                
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditPropertyDrawer}