import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space } from 'antd'
import { ModuleTopHeading, UploadImageModal } from '../../PageComponents'
import { NajiaPropertyTable } from '../NajiaPropertyTable'
import { MyInputnoLabel } from '../../Forms'
import { NavLink } from 'react-router-dom'
import { ClientPropertyTable } from '../ClientPropertyTable'
import { AddEditPropertyDrawer } from '../../PropertyDash'
import { AlertModal } from '../../AlertModal'

const suffix = (
    <Image src='/assets/icons/search.png' width={'18px'} preview={false} />
  );

const TableDashboard = () => {
    const [ current, setCurrent ] = useState(0)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false);
    const [ editproperty, setEditProperty ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)


    const data = {
        head: [
            { item: 'Najla' },
            { item: 'Client' },
        ],
        contenttab: [
            { innercontent: <NajiaPropertyTable {...{editproperty , setEditProperty , deleteitem,setDeleteItem,visibleModal,setVisibleModal, visible, setVisible}}/> },
            { innercontent:  <ClientPropertyTable {...{editproperty , setEditProperty , deleteitem,setDeleteItem,visibleModal,setVisibleModal, visible, setVisible}} />},
        ]
    }

    return (
        <div>
            
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <Space>
                                <ModuleTopHeading name='Properties Overview' />
                                <NavLink to={'/property'} className='fs-12'>View All</NavLink>
                            </Space>
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <Flex justify='end' gap={10}>
                                    <MyInputnoLabel
                                        name='name'
                                        label='Search'
                                        prefix={suffix}
                                        placeholder='Search here ...'
                                        value={form.getFieldValue("name") || ''} 
                                        className='w-100 bg-transparent'
                                        onChange={(e)=>e.target.value}
                                    />
                                </Flex>
                            </Form>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Flex gap={'small'}>
                            {data?.head?.map((headtext, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setCurrent(index)}
                                    className={current === index ? 'btnactive btnpill fs-14 fw-400' : 'btnpill bg-transparent border-black fs-14 fw-400'}
                                >
                                    {headtext?.item}
                                </Button>
                            ))}
                        </Flex>
                    </Col>
                    <Col span={24}>
                        {data?.contenttab[current]?.innercontent}
                    </Col>
                </Row>
            </Card>
            <AddEditPropertyDrawer
                visible={visible}
                editproperty={editproperty}
                onClose={()=> {setVisible(false); setEditProperty(null)}}
            />
            <UploadImageModal
                visible={visibleModal}
                onClose={()=> {setVisibleModal(false)}}
            />
            <AlertModal 
                visible={deleteitem}
                onClose={()=>setDeleteItem(false)}
            />
        </div>
        
    )
}

export { TableDashboard }
