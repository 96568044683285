import React, { useState } from 'react'
import { Modal, Space, Typography, Button, Divider } from 'antd'
import { apiCalls } from '../../shared/Apis';
import { DeleteOutlined } from '@ant-design/icons';
const { Title, Text } = Typography
const AlertModal = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)

    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }
    
  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <img src="/assets/icons/delete-ic.png" width={60} alt="" />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-center fs-13'>Are you sure you want to permanently delete <strong>"{visible? visible?.name:''}"</strong></Text>               
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                        className=''
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        danger
                        loading={loading}
                        className='bg-danger text-white'
                        // onClick={()=>{
                        //     DeleteProductApi();
                        // }}
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}