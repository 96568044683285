import React, { useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Image, Row, Space } from 'antd'
import { AlertModal, ModuleTopHeading, MyInputnoLabel } from '../../components';
import { AddEditExpenseDrawer, ClientExpenseTable, NajiaExpenseTable } from '../../components/ExpenseComponents';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';

const suffix = (
    <Image src='/assets/icons/search.png' width={'18px'} preview={false} />
  )

const Expense = () => {
    const [ current, setCurrent ] = useState(0)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ editexpense, setEditExpense ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [order, setOrder] = useState(1);
    const items = [
        { label: <a href="#">A-Z</a>, key: '0' },
        { label: <a href="#">Z-A</a>, key: '1' },
    ];

    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
      };


    const data = {
        head: [
            { item: 'Najla' },
            { item: 'Client' },
        ],
        contenttab: [
            { innercontent: <NajiaExpenseTable {...{editexpense , setEditExpense , deleteitem, setDeleteItem, visible, setVisible}} /> },
            { innercontent:  <ClientExpenseTable {...{editexpense , setEditExpense , deleteitem, setDeleteItem, visible, setVisible}} />},
        ]
    }

    return (
        <div>
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <Space>
                                <ModuleTopHeading name='Expense' onClick={()=>setVisible(true)} />
                            </Space>
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <Flex justify='end' gap={10}>
                                    <MyInputnoLabel
                                        name='name'
                                        label='Search'
                                        prefix={suffix}
                                        placeholder='Search here ...'
                                        value={form.getFieldValue("name") || ''} 
                                        className='w-100 bg-transparent'
                                        onChange={(e)=>e.target.value}
                                    />
                                    <Dropdown
                                        menu={{ items, onClick }}
                                        trigger={['click']}
                                        className='margin-top'
                                        >
                                        <Button className='btnsave pad-x border0 fs-14 fw-400' >
                                            Filter
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </Flex>
                            </Form>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Flex gap={'small'}>
                            {data?.head?.map((headtext, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setCurrent(index)}
                                    className={current === index ? 'btnactive btnpill fs-14 fw-400' : 'btnpill bg-transparent border-black fs-14 fw-400'}
                                >
                                    {headtext?.item}
                                </Button>
                            ))}
                        </Flex>
                    </Col>
                    <Col span={24}>
                        {data?.contenttab[current]?.innercontent}
                    </Col>
                </Row>
            </Card>
            <AddEditExpenseDrawer 
                visible={visible}
                editexpense={editexpense}
                onClose={()=> {setVisible(false);setEditExpense(false)}}
            />
            <AlertModal 
                visible={deleteitem}
                onClose={()=> setDeleteItem(false)}
            />
        </div>
    )
}

export { Expense }
