import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Image, Upload, message} from "antd"
import { MyInput, MyRentDate, MySelect } from "../../Forms"
import { PropertyCity, PropertyClient, PropertyFor, PropertyOwnership, Propertytype } from "../../../shared/lookups/lookups"

const { Title } = Typography
const { Dragger } = Upload;

const AddEditRentDrawer = ({visible, onClose, editrent}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    
  
    useEffect(() => {
        if (visible && editrent) {
            
        } else {
            form.resetFields();
        }
    }, [visible, editrent])

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };


  return (
    <Drawer
        title={editrent?'Edit Rent':'Add Rent'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editrent? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='my-3 width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/plus.png' width={'30px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-12">Upload Receipt</Typography.Text>
                            </Dragger>
                        </div>
                        {/* {
                            editproperty &&
                            <Space direction='vertical' size={10}>
                                {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                }
                            </Space>
                            
                        } */}
                    </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyRentDate
                        datePicker
                        label="Rent Month"
                        name='rentMonth'
                        size='large'
                        required
                        message="Please select rent month"
                        value={form.getFieldValue('rentMonth') || ''}
                        className='bg-transparent'
                    />
                </Col> 
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        label='Rent Amount'
                        name='rentAmount'
                        placeholder='e.g 1000'
                        size='large'
                        required
                        message='Please enter rent amount'
                        value={form.getFieldValue("rentAmount") || ''}
                    />
                </Col>               
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditRentDrawer}