import React, { useState } from 'react'
import { Card, Col, Flex, Form, Image, Row, Table, Typography, Space, Button, Dropdown } from 'antd'
import { ActionButton, AddEditEmployeeDrawer, AlertModal, ModuleTopHeading, MyInputnoLabel } from '../../components';
import { DownOutlined } from '@ant-design/icons';

const suffix = (
    <Image src='/assets/icons/search.png' width={'18px'} preview={false} />
  )

const { Text } = Typography

const Employeepage = () => {
    const [ visible, setVisible ] = useState(false)
    const [ editemployee, setEditEmployee ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState()
    const [form] = Form.useForm();
    const [order, setOrder] = useState(1);
    const items = [
        { label: <a href="#">A-Z</a>, key: '0' },
        { label: <a href="#">Z-A</a>, key: '1' },
    ];

    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
      };

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Employee Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'employeeName',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        City
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'city',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Contact Number
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'contactNumber',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
        },
        {
            title: <Text >Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 200,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true);setEditEmployee(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.employeeName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            employeeName: <Text >Mohammad</Text>,
            city: <Text >Yanbu</Text>,
            contactNumber: <Text >+966 629 267 736</Text>,
            timeStamp: <Text >01/11/21 02:32PM EST</Text>,
        },
        {
            key: '2',
            employeeName: <Text >Mohammed Darwish</Text>,
            city: <Text >Taif</Text>,
            contactNumber: <Text >+966 311 425 116</Text>,
            timeStamp: <Text >01/11/21 04:10PM EST</Text>,
        },
        {
            key: '3',
            employeeName: <Text >Sameh Amin</Text>,
            city: <Text >Hail</Text>,
            contactNumber: <Text >+966 324 464 232</Text>,
            timeStamp: <Text >01/12/21 06:11PM EST</Text>,
        },
        {
            key: '4',
            employeeName: <Text >Ziyad Abdullah</Text>,
            city: <Text >Al-Khobar</Text>,
            contactNumber: <Text >+966 137 033 326</Text>,
            timeStamp: <Text >01/15/21 09:34AM EST</Text>,
        },
        {
            key: '5',
            employeeName: <Text >Fayez Ali</Text>,
            city: <Text >Jeddah</Text>,
            contactNumber: <Text >+966 798 328 129</Text>,
            timeStamp: <Text >01/11/21 04:10PM EST</Text>,
        },
      ];

    return (
        <div>
            
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <ModuleTopHeading name='Employee' onClick={()=>setVisible(true)} />
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <Flex justify='end' gap={10}>
                                    <MyInputnoLabel
                                        name='name'
                                        label='Search'
                                        prefix={suffix}
                                        placeholder='Search here ...'
                                        value={form.getFieldValue("name") || ''} 
                                        className='w-100 bg-transparent'
                                        onChange={(e)=>e.target.value}
                                    />
                                    <Dropdown
                                        menu={{ items, onClick }}
                                        trigger={['click']}
                                        className='margin-top'
                                        >
                                        <Button className='btnsave pad-x border0 fs-14 fw-400' >
                                            Filter
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </Flex>
                            </Form>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <AddEditEmployeeDrawer
                visible={visible}
                editemployee={editemployee}
                onClose={()=> {setVisible(false);setEditEmployee(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                onClose={()=> setDeleteItem(false)}
            />
        </div>
    )
}

export { Employeepage }
