import React from 'react'
import { BarChart, CardComponent, TableDashboard } from '../../components'
import { Space } from 'antd'

const Dashboard = () => {
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <CardComponent />
        <BarChart />
        <TableDashboard />
    </Space>
  )
}

export {Dashboard}