import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const NajiaExpenseTable = ({setEditExpense , setDeleteItem, setVisible}) => {
  const [form] = Form.useForm();
  

  const columns = [
    {
        title: 
            <Flex align='center' gap={4}>
                <Text >
                    Property Name
                </Text>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'propertyName',
    },
    {
        title:
            <Flex align='center' gap={4}>
                <Text >
                    Expense Name
                </Text>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'expenseName',
    },
    {
        title: 
            <Flex align='center' gap={4}>
                <Text >
                    Expense Amount
                </Text>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'expenseAmount',
    },
    {
        title: 
            <Flex align='center' gap={4}>
                <Text >
                    Added By
                </Text>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'addedBy',
    },
    {
        title: 
            <Flex align='center' gap={4}>
                <Text >
                    Time Stamp
                </Text>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'timeStamp',
    },
    {
        title: <Text >Action</Text>,
        key: 'action',
        fixed: 'right',
        width: 120,
        render: (_, row) => (
            <Space>
                <ActionButton
                    title='Edit item'
                    shape="circle"
                    icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                    onClick={() => { setVisible(true);setEditExpense(true)}}
                    className='border0 bg-transparent'
                />
                <ActionButton
                    title='Delete item'
                    icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                    onClick={() => { setDeleteItem({name:row?.expenseName}) }}
                    className='border0 bg-transparent'
                />
            </Space>
        ),
    },
];

const data = [
    {
        key: '1',
        propertyName: <Space>
            <Avatar
                size={30}
                icon={<img src="/assets/images/av-1.png" />}
            />
            <Text >Lumbini</Text>
        </Space>,
        expenseName: <Text >3605 Parker Rd.</Text>,
        expenseAmount: <Text >Apartment</Text>,
        addedBy: <Text >Own</Text>,
        timeStamp: <Text >11:2 2/22/2023</Text>,
    },
    {
        key: '2',
        propertyName: <Space>
            <Avatar
                size={30}
                icon={<img src="/assets/images/av-1.png" />}
            />
            <Text >Kathmandu</Text>
        </Space>,
        expenseName: <Text >3605 Parker Rd.</Text>,
        expenseAmount: <Text >Apartment</Text>,
        addedBy: <Text >Own</Text>,
        timeStamp: <Text >11:2 2/22/2023</Text>,
    },
    {
        key: '3',
        propertyName: <Space>
            <Avatar
                size={30}
                icon={<img src="/assets/images/av-1.png" />}
            />
            <Text >Sisne-Jaljala</Text>
        </Space>,
        expenseName: <Text >3605 Parker Rd.</Text>,
        expenseAmount: <Text >Apartment</Text>,
        addedBy: <Text >Own</Text>,
        timeStamp: <Text >11:2 2/22/2023</Text>,
    },
    {
        key: '4',
        propertyName: <Space>
            <Avatar
                size={30}
                icon={<img src="/assets/images/av-1.png" />}
            />
            <Text >Chisapani</Text>
        </Space>,
        expenseName: <Text >3605 Parker Rd.</Text>,
        expenseAmount: <Text >Apartment</Text>,
        addedBy: <Text >Own</Text>,
        timeStamp: <Text >11:2 2/22/2023</Text>,
    },
    {
        key: '5',
        propertyName: <Space>
            <Avatar
                size={30}
                icon={<img src="/assets/images/av-1.png" />}
            />
            <Text >Bheri-Kankrebihar</Text>
        </Space>,
        expenseName: <Text >3605 Parker Rd.</Text>,
        expenseAmount: <Text >Apartment</Text>,
        addedBy: <Text >Own</Text>,
        timeStamp: <Text >11:2 2/22/2023</Text>,
    },
  ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {NajiaExpenseTable}