import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Upload, Typography, Image, message} from "antd"
import { MyInput, MySelect } from "../../Forms"
import { DeleteOutlined } from "@ant-design/icons";
import { ActionButton } from "../../PageComponents";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditExpenseDrawer = ({visible, onClose, editexpense, reportonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && editexpense) {
            
        } else {
            form.resetFields();
        }
    }, [visible, editexpense])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={editexpense?'Edit expense':'Add new expense'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editexpense? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                {
                    !reportonly &&
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Property Name'
                            name="propertyName" 
                            placeholder='e.g denmark valley'
                            required
                            size='large'
                            message='Please choose property name'
                            value={form.getFieldValue("propertyName") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Blue Valley'
                                },
                                {
                                    key: 2,
                                    name: 'Capital Society'
                                }
                            ]}
                        />
                    </Col>
                }
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-400">EXPENSE DETAILS</Title>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='my-3 width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-12">Upload bill</Typography.Text>
                            </Dragger>
                        </div>
                        {
                            editexpense &&
                            <Space direction='vertical' style={{width:'50%'}} size={10}>
                                {/* {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                } */}
                                <div className='viewDownload space-between-align'>
                                    <Typography.Text className='text-white fs-12'>
                                        Fan repair bill.jpg
                                    </Typography.Text>
                                    <ActionButton
                                        title='Delete'
                                        icon={<DeleteOutlined/>}
                                        // onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                        type='ghost'
                                    />
                                </div>
                            </Space>
                            
                        }
                    </Form.Item>
                    
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Expense Name'
                        name='expenseName'
                        placeholder='e.g Fan repair'
                        size='large'
                        required
                        message='Please enter expense name'
                        value={form.getFieldValue("expenseName") || ''}
                    />
                </Col> 
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Expense Amount'
                        type='number'
                        name='expenseAmount'
                        placeholder='e.g 10000'
                        size='large'
                        required
                        message='Please enter expense amount'
                        value={form.getFieldValue("expenseAmount") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditExpenseDrawer}