import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import { Layout, Menu, Image, Space, Typography } from 'antd';
import { Dashboard, Login, Booking, Report, PropertyDash, Expense, Client, Employeepage } from '../pages';
import { Notifications, UserProfile } from '../components/Header';

const { Header, Sider, Content } = Layout;
const { Text } = Typography
const RouteF = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [openKeys, setOpenKeys] = useState([]);

  function getItem(label, key, icon, children) {
    return { key, icon, children, label };
  }

  useEffect(() => {
    let tab = location?.pathname?.split("/")[1];
    tab = tab === '' ? '1' :
      tab === 'clients' ? '2' :
      tab === 'employee' ? '3' :
      tab === 'property' ? '4' :
      tab === 'booking' ? '5' :
      tab === 'expense' ? '6' :
      '1';
    setCurrentTab(tab);
    
  }, [location]);

  useEffect(() => {
    let itemsArr = [
      getItem('Daily Rental Management', 'sub1', <img src="/assets/icons/home.png" width={'15px'} alt="" />, [
        getItem('Dashboard', '1'),
        getItem('Client', '2'),
        getItem('Employee', '3'),
        getItem('Property', '4'),
        getItem('Booking', '5'),
        getItem('Expense', '6')
      ]),
      getItem('Team Management', 'sub2', <img src="/assets/icons/tm.png" width={'15px'} alt="" />, [
        getItem('Item6', '6')
      ]),
      getItem('Property Management', 'sub3', <img src="/assets/icons/pm.png" width={'15px'} alt="" />, [
        getItem('Item7', '7')
      ]),
      getItem('Real Estate & AirBnB Management', 'sub4', <img src="/assets/icons/real.png" width={'15px'} alt="" />, [
        getItem('Item8', '8')
      ]),
      getItem('Government Transaction Processing', 'sub5', <img src="/assets/icons/gtp.png" width={'15px'} alt="" />, [
        getItem('Item1', '9'),
        getItem('Item2', '10'),
        getItem('Item3', '11')
      ])
    ];

    setItems([...itemsArr]);
  }, []);

  const handleMenuClick = (e) => {
    const { key } = e;
    switch (key) {
      case '1':
        navigate("/", { replace: true });
        break;
      case '2':
        navigate("/clients", { replace: true });
        break;
      case '3':
        navigate("/employee", { replace: true });
        break;
      case '4':
        navigate("/property", { replace: true });
        break;
      case '5':
        navigate("/booking", { replace: true });
        break;
      case '6':
        navigate("/expense", { replace: true });
        break;
      case '7':
        navigate("/item6", { replace: true });
        break;
      case '8':
        navigate("/item7", { replace: true });
        break;
      case '9':
        navigate("/item8", { replace: true });
        break;
      case '10':
        navigate("/item1", { replace: true });
        break;
      case '11':
        navigate("/item2", { replace: true });
        break;
      case '12':
        navigate("/item3", { replace: true });
        break;
      default:
        break;
    }
  };

 
  const onOpenChange = (keys) => {
    console.log('Dropdown Head',keys)
    setOpenKeys(keys);
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100vh', overflowY: 'auto' }}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ width: collapsed ? "100%" : '100px' }}
            height={'auto'}
            src="./assets/images/logo.png"
            alt='Rental Admin Panel'
            preview={false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          items={items}
          className='listitem'
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background header-mbl-cs shadow-c"
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
            <Space align='center' size={5}>
              <div className='trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                <Image src='/assets/icons/star-m.png' width={'25px'} preview={false} />
              </div>
              <Text strong>Welcome To Admin Panel!</Text>
            </Space>
            <Space size={15}>
              <Image src='/assets/icons/language.png' width={24} preview={false} />
              <Image src='/assets/icons/question.png' width={24} preview={false} />
              <Notifications />
              <UserProfile />
            </Space>
          </div>
        </Header>
        <Content
          className="scroll-bar width-fix"
          style={{
            margin: '24px auto',
            marginTop: '10',
            padding: '0 15px',
            minHeight: 280,
            overflowY: 'auto'
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/employee" element={<Employeepage />} />
            <Route path="/property" element={<PropertyDash />} />
            <Route path="/expense" element={<Expense />} />
            <Route path="/clients" element={<Client />} />
            <Route path="/report" element={<Report />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RouteF;
