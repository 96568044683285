import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Upload, Typography, Image, message} from "antd"
import { MyInput, MySelect } from "../../Forms"
import { PropertyCity } from "../../../shared/lookups/lookups"

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditClientDrawer = ({visible, onClose, editclient}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && editclient) {
            
        } else {
            form.resetFields();
        }
    }, [visible, editclient])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={editclient?'Edit Client':'Add Client'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editclient? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-400">CLIENT DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Client Name'
                        name='guestName'
                        placeholder='e.g John Doe'
                        size='large'
                        required
                        message='Please enter client name'
                        value={form.getFieldValue("guestName") || ''}
                    />
                </Col>
          
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Email address'
                        name='guestName'
                        placeholder='e.g ali@gmail.com'
                        size='large'
                        required
                        message='Please enter customer name'
                        value={form.getFieldValue("guestName") || ''}
                    />
                </Col>                
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Phone Number'
                        type='number'
                        name='guestContactNumber'
                        placeholder='e.g +966 12345689'
                        size='large'
                        required
                        message='Please enter guest contact number'
                        value={form.getFieldValue("guestContactNumber") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Nationality'
                        name="nationality" 
                        placeholder='e.g saudi'
                        size='large'
                        required
                        message='Please choose nationality'
                        value={form.getFieldValue("nationality") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Saudi Arab'
                            },
                            {
                                key: 2,
                                name: 'Australia'
                            }
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'City'
                        name="city" 
                        placeholder='e.g Tabook'
                        size='large'
                        required
                        message='Please choose city'
                        value={form.getFieldValue("city") || ''}
                        options={PropertyCity}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Residential Address'
                        name='residentialAddress'
                        placeholder='e.g House 12, Blue Valley'
                        size='large'
                        required
                        message='Please enter residential address'
                        value={form.getFieldValue("residentialAddress") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-400">DOCUMENTS</Title>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='my-3'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-12">Owner Identity Card (Front)</Typography.Text>
                            </Dragger>
                        </div>
                        {/* {
                            editproperty &&
                            <Space direction='vertical' size={10}>
                                {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                }
                            </Space>
                            
                        } */}
                    </Form.Item>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='my-3'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon">
                                    <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-12">Contract Copy</Typography.Text>
                            </Dragger>
                        </div>
                        {/* {
                            editproperty &&
                            <Space direction='vertical' size={10}>
                                {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                }
                            </Space>
                            
                        } */}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditClientDrawer}