import React, { useState } from 'react'
import { Card, Col, Flex, Form, Image, Row, Table, Typography, Space, Button, Dropdown } from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyInputnoLabel } from '../../components';
import { AddEditClientDrawer, PropertyModal } from '../../components/ClientComponents';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';

const suffix = (
    <Image src='/assets/icons/search.png' width={'18px'} preview={false} />
  )

const { Text } = Typography

const Client = () => {
    const [ visible, setVisible ] = useState(false)
    const [visiblemodal, setVisibleModal] = useState(false);
    const [ editclient, setEditClient ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState()
    const [form] = Form.useForm();
    const [order, setOrder] = useState(1);
    const items = [
        { label: <a href="#">A-Z</a>, key: '0' },
        { label: <a href="#">Z-A</a>, key: '1' },
    ];

    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        // filter(key);
      };

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        Client Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'clientName',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text >
                        City
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'city',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Number of Properties
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'numberofproperties',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text >
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
        },
        {
            title: <Text >Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 200,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true);setEditClient(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Property'
                        icon={<Image src='/assets/icons/property.png' width={16} preview={false} />}
                        onClick={() => { setVisibleModal(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.clientName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            clientName: <Text >Najla</Text>,
            city: <Text >Sderot</Text>,
            numberofproperties: <Text >4</Text>,
            timeStamp: <Text >11:20PM 2/22/2023</Text>,
        },
        {
            key: '2',
            clientName: <Text >Mohammed Darwish</Text>,
            city: <Text >Tirat Carmel	</Text>,
            numberofproperties: <Text >4</Text>,
            timeStamp: <Text >11:20PM 2/22/2023</Text>,
        },
        {
            key: '3',
            clientName: <Text >Sameh Amin</Text>,
            city: <Text >Taibeh</Text>,
            numberofproperties: <Text >5</Text>,
            timeStamp: <Text >11:20PM 2/22/2023</Text>,
        },
        {
            key: '4',
            clientName: <Text >Zuhair Hafeez</Text>,
            city: <Text >Tamra</Text>,
            numberofproperties: <Text >4</Text>,
            timeStamp: <Text >11:20PM 2/22/2023</Text>,
        },
        {
            key: '5',
            clientName: <Text >Jihad Bakir</Text>,
            city: <Text >Yehud-Monosson</Text>,
            numberofproperties: <Text >7</Text>,
            timeStamp: <Text >11:20PM 2/22/2023</Text>,
        },
      ];

    return (
        <div>
            
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <ModuleTopHeading name='Client' onClick={()=>setVisible(true)} />
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <Flex justify='end' gap={10}>
                                    <MyInputnoLabel
                                        name='name'
                                        label='Search'
                                        prefix={suffix}
                                        placeholder='Search here ...'
                                        value={form.getFieldValue("name") || ''} 
                                        className='w-100 bg-transparent'
                                        onChange={(e)=>e.target.value}
                                    />
                                    <Dropdown
                                        menu={{ items, onClick }}
                                        trigger={['click']}
                                        className='margin-top'
                                        >
                                        <Button className='btnsave pad-x border0 fs-14 fw-400' >
                                            Filter
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </Flex>
                               
                            </Form>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <AddEditClientDrawer
                visible={visible}
                editclient={editclient}
                onClose={()=> {setVisible(false);setEditClient(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                onClose={()=> setDeleteItem(false)}
            />

            <PropertyModal 
                visiblemodal={visiblemodal}
                onClose={()=> setVisibleModal(false)}
            />
        </div>
    )
}

export { Client }
