import { useEffect, useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Checkbox, Flex } from "antd"
import {  MyInput } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import "./index.css"

const {Title, Text}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)

    
    
    // const login = () => {
    //     if(forgotPassword)
    //         appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
    //     else
    //         appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    // }
   
    

    return (
        <div className='login-form-cover'>
            <Flex justify="center">
                <div className="login-form">
                    <Row gutter={16} className="login-form px-2 py-2">
                        <Col 
                            md={15} sm={0} xs={0} className="">
                            <div className="w-100 img-logo p-5 brand-bg">
                                <Image 
                                    src="/assets/images/logo-t.png" 
                                    width={'50%'} 
                                    preview={false} 
                                    alt="rental-logo"
                                />
                            </div>
                        </Col>
                        <Col 
                            md={9} sm={24} xs={24} 
                            className="bg-white login-form-right-side"
                        >
                            <Space 
                                direction='vertical' 
                                className='justify-center py-5'
                                size={15}
                                >
                                    <Image src="/assets/images/logo-sm.png" width={'60px'} preview={false} />
                                <div>
                                    <Title 
                                        level={4} 
                                        className=" mt-0"
                                    >
                                        Welcome to Najla Relator System
                                    </Title>
                                    <Text className="text-gray">
                                        Enter the credentials to login to the system
                                    </Text>
                                </div>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    style={{ width: '100%' }}
                                    // onFinish={login}
                                    requiredMark={false}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <MyInput
                                                autoFocus
                                                name="email"
                                                label="Email Address"
                                                required
                                                size={'large'}
                                                message='Enter email address'
                                                value={form.getFieldValue("email") || ''}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <MyInput
                                                name="password"
                                                type='password'
                                                label="Password"
                                                required
                                                size={'large'}
                                                message='Enter password'
                                                value={form.getFieldValue("password") || ''}
                                            />
                                        </Col>
                                        <Col span={24} className="center">
                                            <Button 
                                                htmlType="submit" 
                                                size="large"
                                                block
                                                loading={loading}
                                                className="fs-14 bg-black text-white"
                                                >
                                                Signin
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Space>
                        </Col>
                    </Row>
                </div>
                
                {/* <Text strong className="pt-3 light-grey-text">
                    Version <span className="brand-color">0.2</span> Beta Release
                </Text> */}
            </Flex>
        </div>
    )
}
export {Login}