const PropertyFor = [
    {
        name: 'Najla',
        id : 1
    },
    {
        name: 'Client',
        id : 2
    },
]

const PropertyClient = [
    {
        name: 'M.Ali',
        id:1
    },
    {
        name: 'Arbaz Khan',
        id:2
    },
    {
        name: 'Shujat Ali',
        id:3
    },
]

const PropertyCity = [
    {
        name: 'Islamabad',
        id: 1
    },
    {
        name: 'Lahore',
        id: 2
    },
]

const Propertytype = [
    {
        name: 'Room',
        id: 1
    },
    {
        name: 'Appartment',
        id: 2
    },
]

const PropertyOwnership = [
    {
        name:'Own',
        id: 1
    },
    {
        name: 'Rented',
        id: 2
    }
]

export {PropertyFor, PropertyClient, PropertyCity, Propertytype, PropertyOwnership}